var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('vue-tooltip', {
    attrs: {
      "displayText": _vm.getHumanizeDate || '-',
      "text": _vm.formattedTooltipText || '-'
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }